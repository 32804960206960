import {
  TabList as MuiTabList,
  type TabListTypeMap as MuiTabListTypeMap
} from '@mui/lab';
import { styled } from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxTabListCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxTabListTypeMap<P = object, D extends React.ElementType = 'button'> = {
  props: P & Omit<AxTabListCustomProps & MuiTabListTypeMap<P, D>['props'], 'indicatorColor' | 'textColor'> ;
  defaultComponent: D;
};

export type AxTabListProps<
  D extends ElementType = AxTabListTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTabListTypeMap<P & AxTabListCustomProps, D>, D>;


const styledOptions = {
  name: 'AxTabList'
};

const StyledAxTabList = styled(MuiTabList, styledOptions)<AxTabListProps>((props) => {
  const {
    theme
  } = props;

  return {
    minHeight: theme.uiKit.tabs.height,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.uiKit.tabs.selected.borderColor,
      height: theme.uiKit.tabs.divider.height
    },
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '& .MuiTabs-scroller': {
      borderBottom: theme.uiKit.tabs.divider.borderBottom
    }
  };
});

export const AxTabList: MuiOverridableComponent<AxTabListTypeMap> = forwardRef(({
  variant = 'scrollable',
  scrollButtons = 'auto',
  children,
  ...otherProps
}: AxTabListProps, ref: Ref<HTMLButtonElement>) => {

  const tabListProps: AxTabListProps = {
    children,
    variant,
    scrollButtons,
    ...otherProps
  };

  return (
    <StyledAxTabList
      { ...tabListProps }
      ref={ ref }
    >
      { children }
    </StyledAxTabList>
  );
});

export default AxTabList;
