const _ = require('underscore');
const logging = require('logging');

const Backbone = require('Backbone');
const UIKit = require('@training/widgets/UIKit');

const ProfileIconHandler = require('@common/components/ProfileIconHandler');

const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const MonthYearPicker = require('@common/components/monthYearPicker/MonthYearPicker');
require('@common/libs/behaviors/msHideable/MsHideable');

class TopPerformersPage extends UIKit.View {

  static TAB_NAME = {
    MONTH: 'month',
    LAST_30_DAYS: 'last30Days',
    ALL_TIME: 'allTime'
  };

  constructor(options = {}) {
    super(options);

    ({
      initialTab: this.initialTab = TopPerformersPage.TAB_NAME.MONTH
    } = options);

    this._createLeaderboardView = this._createLeaderboardView.bind(this);

    this.template = _.tpl(require('@training/apps/training/templates/TopPerformersPage.html'));
    const cycleType = TenantPropertyProvider.get().getProperty('leaderboardsCycle') || '';
    this.isMonthlyLeaderboard = cycleType.toLowerCase() === 'monthly';
    this.isAllTimeEnabled = TenantPropertyProvider.get().getProperty('allTimeLeaderboardsOptionAvailable')
      && TenantPropertyProvider.get().getProperty('allTimeLeaderboardsEnabled');

    this.tabHashes = {
      [TopPerformersPage.TAB_NAME.MONTH]: '#hub/topPerformers/month',
      [TopPerformersPage.TAB_NAME.LAST_30_DAYS]: '#hub/topPerformers/last30Days',
      [TopPerformersPage.TAB_NAME.ALL_TIME]: '#hub/topPerformers/allTime'
    };

    this.availableTabs = {
      [TopPerformersPage.TAB_NAME.MONTH]: this.isMonthlyLeaderboard,
      [TopPerformersPage.TAB_NAME.LAST_30_DAYS]: !this.isMonthlyLeaderboard,
      [TopPerformersPage.TAB_NAME.ALL_TIME]: this.isAllTimeEnabled
    };
  }

  className() {
    return 'top-performers-page';
  }

  behaviors() {
    return {
      MsHideable: { selector: '.page-header' }
    };
  }

  render() {
    logging.info(`TopPerformersPage - Is alltime leaderboard enabled?: ${ this.isAllTimeEnabled }`);
    logging.info(`TopPerformersPage - Is monthly leaderboard enabled?: ${ this.isMonthlyLeaderboard }`);

    this.$el.html(
      this.template({
        isAllTimeEnabled: this.isAllTimeEnabled,
        isMonthlyLeaderboard: this.isMonthlyLeaderboard
      })
    );

    this.tabs = new UIKit.TabBar({
      el: this.$('.tab-bar'),
      fadeDuration: 0,
      container: this.$('.leaderboard-listcontainer')
    });

    this.listenTo(this.tabs, 'change:tabs', this.onChangeTabs);

    this.$('.tab-bar').toggleClass('hidden', this.tabs.length === 0);

    return this;
  }

  viewDidAppear() {
    logging.info('TopPerformersPage - viewDidAppear');
    window.app.layout.setTitle(I18n.t('stats.title'));
    this.clickDefaultTab();
  }

  clickDefaultTab() {
    let tabName = this.initialTab;

    if (!this.availableTabs[this.initialTab]) {
      tabName = this.tabs.tabNames[0];
    }

    if (tabName != null) {
      this.tabs.clickTab(tabName);
    }
  }

  onChangeTabs(tabname) {
    Backbone.history.navigate(this.tabHashes[tabname], {
      trigger: false,
      replace: true
    });

    if (!this.tabs.views[tabname]) {
      const v = this._createLeaderboardView(tabname);
      this.tabs.setViewForTabName(v, tabname);
    }

    this.triggerAdjustment();
  }

  onClose() {
    super.onClose();
    const complete = this.getOption('complete') || (() => {});
    complete();
  }

  _createLeaderboardView(timePeriod) {
    const v = new TopPerformersLeaderboard({
      timePeriod,
      teamName: window.apps.auth.session.user.get('location').name
    });

    v.render();

    return v;
  }
}

//TODO consider moving this into another file -- probably not though since this is only really useful for this one page
class TopPerformersLeaderboard extends UIKit.View {
  className() {
    return 'leaderboard-view clearfix';
  }

  initialize(options = {}) {
    this.template = _.tpl(require('@training/apps/training/templates/TopPerformersPageLeaderboard.html'));
    this.userRowTemplate = _.tpl(require('@training/apps/training/templates/_top_performers_user_row.html'));
    this.locationRowTemplate = _.tpl(require('@training/apps/training/templates/_top_performers_location_row.html'));

    // Binding is required to be performed here since there are required things inside of the actual items
    // below, such as the event handlers
    this.onDateChanged = this.onDateChanged.bind(this);
    this.addTrophy = this.addTrophy.bind(this);
    this.renderRankList = this.renderRankList.bind(this);
    this.setTimePeriod = this.setTimePeriod.bind(this);
    this.loadLeaderboardData = this.loadLeaderboardData.bind(this);
    this.displayData = this.displayData.bind(this);
    this.updateCaptionPerson = this.updateCaptionPerson.bind(this);
    this.updateCaptionTeam = this.updateCaptionTeam.bind(this);
    this.updateCaption = this.updateCaption.bind(this);

    this.userList = {};
    this.locationList = {};
    this.loadingData = {};
    let { timePeriod } = options;
    const { teamName } = options;
    this.isMonthlyLeaderboard = timePeriod === 'month';
    this.performersRequest = null;

    const timeFrame = I18n.t(`stats.timeframe.${ timePeriod }`);

    this.$el.html(
      this.template({
        isMonthlyLeaderboard: this.isMonthlyLeaderboard,
        teamName,
        timeFrame
      })
    );

    if (this.isMonthlyLeaderboard) {
      const currentDate = new Date();

      // Given the tenant creation date, we can use this to figure out when to start the date
      const tenantCreateDateTimestamp = TenantPropertyProvider.get().getProperty('createDate');
      const tenantCreationDate = new Date(tenantCreateDateTimestamp);
      const tenantCreationYear = tenantCreationDate.getFullYear();
      const yearDelta = currentDate.getFullYear() - tenantCreationYear;
      const MAX_YEARS_BACK_TRAILING = 5;
      const yearsToLookBack = Math.min(MAX_YEARS_BACK_TRAILING, yearDelta);

      logging.info(`TopPerformersPage - Tenant create date bootstrapped as ${ tenantCreateDateTimestamp }`);

      const currentMonth = currentDate.getMonth() + 1;
      timePeriod
        = currentMonth > 9
          ? `${ currentDate.getFullYear() }${ currentMonth }`
          : `${ currentDate.getFullYear() }0${ currentMonth }`;

      logging.info(`TopPerformersPage - Time period is being bootstrapped as ${ timePeriod }`);
      logging.info(`TopPerformersPage - The epoch timestamp was bootstrapped as ${ currentDate.getTime() }`);

      this.formDates = new MonthYearPicker({
        el: this.$('#dateForm'),
        onDateChanged: this.onDateChanged,
        minYear: currentDate.getFullYear() - yearsToLookBack
      });
    }

    this.setTimePeriod(timePeriod);
  }

  onDateChanged(date) {
    logging.info(`TopPerformersPage - Date has changed, reporting ${ date.month } and ${ date.year }`);
    const newMonth = date.month + 1;
    const timePeriod = newMonth > 9 ? `${ date.year }${ newMonth }` : `${ date.year }0${ newMonth }`;
    this.setTimePeriod(timePeriod);
  }

  addTrophy($row, rank, rowType) {
    let listType = '';

    if (rowType === 'team') {
      listType = 'team-';
    }

    const trophies = [
      {
        rank: 0,
        colour: 'gold',
        ariaLabelKey: 'stats.rank1trophy'
      },
      {
        rank: 1,
        colour: 'silver',
        ariaLabelKey: 'stats.rank2trophy'
      },
      {
        rank: 2,
        colour: 'bronze',
        ariaLabelKey: 'stats.rank3trophy'
      }
    ];

    const targetTrophy = _.find(trophies, (trophy) => {
      return trophy.rank === rank;
    });
    const ariaLabel = I18n.t(targetTrophy.ariaLabelKey);

    return $row
      .find('td.rank-wrapper .rank')
      .addClass(`${ targetTrophy.colour }-border`)
      .html(`<div role="img" aria-label="${ ariaLabel }" class='trophy ${ targetTrophy.colour }-${ rowType } icon-${ listType }trophy-${ targetTrophy.colour } ${ targetTrophy.colour }'></div>`);
  }

  // Abstract method that renders the list of users/locations so we can re-use the same logic.
  // This is a dumb renderer that will add all the entries sent in the `entries`
  // param. It's up to you to filter out/limit entires that you don't want displayed.
  renderRankList(entries, $tableList, template, emptyStringValue = '', type, updateCaptionFn, timePeriod) {
    const $caption = $tableList.find('caption');

    // clear all except first row, which is the header row
    $tableList.find('tr:gt(0)').remove();

    if (entries.length > 0) {
      for (let index = 0; index < entries.length; index++) {
        let item = entries[index];
        if (item.current) {
          item = _.extend({}, item, { isMe: true });
        }

        const $tableRow = $(template({ item }));
        if ([0, 1, 2].includes(item.rank)) {
          //add trophy if the entry's rank requires it
          this.addTrophy($tableRow, item.rank, type);
        }

        const $currentView = $tableRow.find('.name-logo');
        // eslint-disable-next-line no-new
        new ProfileIconHandler({
          user: item,
          profileElement: $currentView
        });

        $tableList.append($tableRow);
      }

      const currentRank = this.getCurrentRank(entries);
      updateCaptionFn($caption, currentRank, timePeriod);

      //insert a break after the 10th place person in the user list only if the
      // user's rank is 12+ or -1
      if (currentRank > 11 || currentRank === -1) {
        const tenth = $tableList.find('tr.ranked-row[data-rank=\'9\']');
        const tdCount = tenth.children('td').length;
        const separatorTd = `<td colspan=${ tdCount }><span class="off-screen">${ I18n.t('stats.separatorRow') }</span></td>`;
        tenth.after(`<tr class="row-separator">${ separatorTd }</tr>`);
      }
    } else {
      $tableList.append(`<tr class="ranked-row"><td colspan="4" class="center">${ emptyStringValue }</td></tr>`);
      $caption.html(emptyStringValue);
    }

    this.trigger('force:icon:reflow');
  }

  // Return index in the entry list for the current user's attribute.
  currentUser(list) {
    return _(list).find((item) => {
      return item.current;
    });
  }

  // Returns the user's rank in the given list of objects.
  getCurrentRank(list) {
    const found = this.currentUser(list);
    return found != null ? found.rank : undefined;
  }

  setTimePeriod(timePeriod) {
    this.timePeriod = timePeriod;

    //Only one request should ever be happening at a time
    if (this.performersRequest != null) {
      this.performersRequest.abort();
    }

    this.$('table > tbody').empty();
    if (_.isEmpty(this.userList[this.timePeriod]) && _.isEmpty(this.locationList[this.timePeriod])) {
      this.loadLeaderboardData(this.timePeriod);
      this.triggerAdjustment();
    } else {
      this.displayData(this.timePeriod);
    }
  }

  loadLeaderboardData(timePeriod) {
    let parameters;
    if (this.isMonthlyLeaderboard) {
      parameters = {
        timePeriod: 'month',
        timePeriodDate: timePeriod
      };
    } else {
      parameters = { timePeriod };
    }

    logging.info(`TopPerformersPageLeaderboard - Loading leaderboard for ${ timePeriod }`);
    // GET /performers returns an object with {locations, users}, each having up to
    // 13 entries, where 0-9 is the top ten, and the optional 10-12 entries are
    // the current user padded with up to 1 entry on either side
    (this.performersRequest = $.ajax({
      type: 'GET',
      apiEndpoint: '/performers',
      data: parameters,
      error() {
        logging.error(`TopPerformersPageLeaderboard - Error loading Top Performers - ${ timePeriod }`);
      },
      success: (response) => {
        logging.info(`TopPerformersPageLeaderboard - Top Performers loaded - ${ timePeriod }`);
        this.userList[timePeriod] = response.leaderboard.users != null ? response.leaderboard.users : [];
        this.locationList[timePeriod] = response.leaderboard.locations != null ? response.leaderboard.locations : [];
        this.displayData(timePeriod);
      }
    }));
  }

  onClose() {
    this.performersRequest.abort();
  }

  render() {
    if (this.isMonthlyLeaderboard) {
      this.formDates.render();
    }
  }

  displayData(timePeriod) {
    //if for some reason multiple requests return then
    //make sure only to display the data if it's for the current time period.
    if (timePeriod !== this.timePeriod) {
      return;
    }

    this.renderRankList(
      this.userList[timePeriod],
      this.$('table.usertable'),
      this.userRowTemplate,
      I18n.t('stats.noUsers'),
      'person',
      this.updateCaptionPerson,
      timePeriod
    );
    this.renderRankList(
      this.locationList[timePeriod],
      this.$('table.storetable'),
      this.locationRowTemplate,
      I18n.t('stats.noTeams'),
      'team',
      this.updateCaptionTeam,
      timePeriod
    );
    this.triggerAdjustment();
  }

  updateCaptionPerson($caption, currentRank, timePeriod) {
    const tableHeader = I18n.t('stats.people', {
      teamName: window.apps.auth.session.user.get('location').name,
      timeFrame: I18n.t(`stats.timeframe.${ this.isMonthlyLeaderboard ? 'month' : timePeriod }`)
    });
    this.updateCaption($caption, currentRank, 'stats.yourranklisted', 'stats.yourrankunlisted', tableHeader);
  }

  updateCaptionTeam($caption, currentRank, timePeriod) {
    const tableHeader = I18n.t('stats.teams', {timeFrame: I18n.t(`stats.timeframe.${ this.isMonthlyLeaderboard ? 'month' : timePeriod }`)});
    this.updateCaption($caption, currentRank, 'stats.yourteamranklisted', 'stats.yourteamrankunlisted', tableHeader);
  }

  updateCaption($caption, currentRank, shownLangKey, unlistedLangKey, tableHeader) {
    if (currentRank || currentRank === 0) {
      $caption.html(`${ tableHeader }. ${ I18n.t(shownLangKey, { rank: currentRank + 1 }) }`);
    } else {
      $caption.html(`${ tableHeader }. ${ I18n.t(unlistedLangKey) }`);
    }
  }

  viewDidAppear() {
    logging.info(`TopPerformersPageLeaderboard - ${ this.timePeriod } - viewDidAppear`);
    this.triggerAdjustment();
  }
}

module.exports = TopPerformersPage;
