import { getCssValue } from '@common/modules/react/themes/UiKit';
export default function uiKitTabs(cssVars: CSSStyleDeclaration) {
  return {
    tabs: {
      lineHeight: getCssValue('--ax-tabs-lineHeigh', cssVars),
      color: getCssValue('--ax-tabs-color', cssVars),
      fontSize: getCssValue('--ax-tabs-fontSize', cssVars),
      fontWeight: getCssValue('--ax-tabs-fontWeight', cssVars),
      height: getCssValue('--ax-tabs-height', cssVars),
      divider: {
        borderBottom: getCssValue('--ax-tabs-divider-borderBottom', cssVars),
        color: getCssValue('--ax-tabs-divider-color', cssVars),
        height: getCssValue('--ax-tabs-divider-height', cssVars)
      },
      selected: {
        color: getCssValue('--ax-tabs-color-selected', cssVars),
        borderColor: getCssValue('--ax-tabs-borderColor-selected', cssVars, '--ax-color-semantic-informational') // branded color
      },
      padding: getCssValue('--ax-tabs-padding', cssVars),
      hover: {
        background: getCssValue('--ax-tabs-background-hover', cssVars)
      }
    }
  };
}
