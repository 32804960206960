const I18n = require('@common/libs/I18n');
const LearningPathExplorerController = require('@common/components/learningPathExplorer/LearningPathExplorerController');

require('@common/libs/behaviors/mutationObservable/MutationObservable');

module.exports = (collection, getCardViewDefinitionFn) => {
  return {
    tab: {
      tabName: 'paths',
      tabText: I18n.t('learnerTrainingPage.tabTitle.path'),
      tabClass: 'ax-grid__col--4 ax-grid__col--m-auto-size'
    },
    tabContentController: () => {
      return {
        ViewControllerClass: LearningPathExplorerController,
        pathCollection: collection,
        getCardViewDefinitionFn
      }
    }
  }
};
